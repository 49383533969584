// IMPORTS
@import (once) "/node_modules/swiper/swiper.less";
@import (once) "/node_modules/swiper/modules/navigation.less";
@import (once) "/node_modules/swiper/modules/pagination.less";
@import (once) "/node_modules/swiper/modules/autoplay.less";

@import (once) "settings.less";

// GENERAL
body {
    overflow-x: hidden;
    background-color: white;
}

.goshop-modal-content {
    display: none;
}

#container {
    min-height: 100vh;
    min-height: -webkit-fill-available;

    // IMPORTANT
    display: flex;
    flex-direction: column;

    > *:not(.inner) {
        width: 100%;
    }

    #footer {
        margin-top: auto;
    }
}

// SHOP-HEADER
.header-sentinel {
    position: absolute;
    top: 0;
    left: 0;
}

.privacy-sentinel {
    height: 0;
    clear: both;
}

@media (min-width: 1280px) {
    .shop-header {
        margin-bottom: 32px;
    }
}

.shop-header-container {
    display: flex;
    align-items: center;
    height: 150px;
    justify-content: flex-end;
    gap: 80px;
}

// LOGO
.logo {
    display: flex;
    margin-right: auto;
    position: relative;

    img {

        &:nth-child(1) {
            width: auto;
            height: 84px;
        }

        &:nth-child(2) {
            position: absolute;
            width: auto;
            height: 15px;
            left: 0;
            bottom: 3px;
        }
    }
}

.header-contact-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.header-contact {
    .line-height();
    display: flex;

    li {

        &:nth-child(2) {
            margin-left: 51px;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -25px;
                width: 1px;
                height: 100%;
                background-color: @border-color;
            }
        }
    }

    a {
        color: @header-color;

        &:after {
            font-family: icomoon;
            float: left;
            font-size: 18px;
            margin-right: 8px;
        }

        &:hover {
            text-decoration: underline;
        }
    }

    .phone:after {
        content: '\e90f';
    }

    .email:after {
        content: '\e90e';
    }
}

.header-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
}

.desktop-account-actions {
    display: flex;
    gap: 24px;

    a {
        .line-height();
        color: @font-color;

        &:after {
            content: '\e912';
            font-family: icomoon;
            float: right;
            margin-left: 5px;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

// HEADER ICONS
.header-tools-wrapper {
    display: flex;
    flex-wrap: wrap;
}

@media (min-width: 1280px) {
    .tree-trigger {
        display: none;
    }

    .tree-canvas {
        display: none;
    }

    #cart-box {
        .line-height();
        color: @header-color;
        display: flex;
        gap: 20px;

        &:hover b {
            color: @main-color;
        }

        &:before {
            content: '\e910';
            font-family: icomoon;
            font-size: 26px;
        }

        b {
            display: block;
            white-space: nowrap;

            &:after {
                content: '\e912';
                font-family: icomoon;
                float: right;
                font-weight: normal;
                font-size: 18px;
                position: relative;
                top: 0;
                margin-left: 10px;
            }
        }

        em {
            display: none;
        }
    }

    a.log-button {
        display: none;
    }
}

// MAIN BAR
#main-bar {
    position: relative;
    background-color: #292b2f;
    border-bottom: 4px solid @main-color;
    display: flex;
}

// MAINMENU
.mainmenu {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    li {
        flex-grow: 1;
    }

    a {
        height: 57px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: white;
        font-family: @sec-font-family;
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;

        &:hover {
            background-color: @main-color;
            color: white;
        }
    }
}

// SEARCH ENGINE
.search-engine-trigger,
.search-engine-close {
    display: none;
}

@media (min-width: 1280px) {
    #search-engine {
        position: relative;
        width: 408px;

        &.show {

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-last-phrases {
                    display: none;
                }
            }
        }

        &.show-with-last-phrases {

            &.no-products .no-products-info,
            a.suggestions-advanced-search {
                display: none;
            }

            #suggestions {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                transition: all .2s ease-in-out;

                .suggestion-result-categories,
                .suggestion-result-producers,
                .suggestion-result-products {
                    display: none;
                }
            }

            a.suggestions-advanced-search {
                display: block;
            }
        }

        &.no-products {

            .no-products-info {
                display: block;

                & + .suggestion-column-wrapper .suggestion-left-column:not(.hidden) {
                    margin-top: 10px;
                }
            }
        }

        input[type="text"] {
            width: 100%;
            height: 57px;
            padding: 0 72px 0 22px;
            background-color: #171819;
            color: #e1e1e1;
            font-size: 16px;
            font-family: @main-font-family;
            border: 0;

            &::-webkit-input-placeholder {
                color: #e1e1e1;
            }
            &::-moz-placeholder {
                color: #e1e1e1;
            }
            &:-ms-input-placeholder {
                color: #e1e1e1;
            }
            &:-moz-placeholder {
                color: #e1e1e1;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 72px;
            height: 57px;
            color: white;
            font-size: 20px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:after {
                content: '\e80d';
                font-family: icomoon;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

// SUGGESTIONS
#suggestions {
    position: absolute;
    top: ~"calc(100% + 10px)";
    left: 0;
    background-color: white;
    width: 720px;
    padding: 22px;
    .shadow();
    opacity: 0;
    visibility: hidden;
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-10px);
    z-index: 100;

    .no-products-info {
        display: none;
        font-size: 18px;
        font-weight: bold;
        color: @header-color;
        width: 100%;
    }

    &.loading {

        > *:not(.suggestions-loading) {
            display: none;
        }

        .suggestions-loading {
            display: flex;
        }
    }
}

.suggestions-loading {
    height: 200px;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: none;

    span {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

.suggestion-column-wrapper {
    display: flex;
    width: 100%;
}

.suggestion-left-column {
    width: 33%;
    flex-shrink: 0;

    &.hidden {
        display: none;
    }
}

.suggestion-result-categories,
.suggestion-result-producers,
.suggestion-result-products,
.suggestion-last-phrases {

    &.hidden {
        display: none;
    }

    &:before {
        content: attr(data-label);
        display: block;
        color: @header-color;
        font-weight: bold;
        font-size: 18px;
    }
}

.suggestion-result-categories,
.suggestion-result-producers {
    padding-right: 30px;

    a {
        display: inline-block;
        padding: 2px 0;
        color: @font-color;

        &:hover {
            text-decoration: underline;
        }
    }
}

.suggestion-last-phrases {
    width: 100%;

    span {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px 0;

        &:before {
            content: '\e802';
            font-family: icomoon;
            color: @header-color;
            float: left;
            width: 35px;
            font-size: 18px;
            flex-shrink: 0;
        }

        &:hover {
            color: @header-color;
        }
    }

    li {
        display: flex;
        align-items: center;

        &.hidden {
            display: none;
        }
    }

    .delete-hint-suggestion {
        margin-left: auto;
        font-style: normal;
        color: @header-color;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 11px;
        cursor: pointer;

        &:after {
            content: '\e803';
            font-family: icomoon;
        }

        &:hover {
            color: @error-color;
        }
    }
}

.suggestion-result-categories {
    margin-bottom: 22px;
}

.suggestion-result-products {
    flex-grow: 1;
}

.suggestion-product {
    margin-top: 10px;

    &:hover {
        
        b {
            text-decoration: underline;
        }
    }

    a {
        display: block;
        position: relative;
        min-height: 60px;
        padding-left: 80px;
    }

    .size-custom {
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
    }

    b {
        color: @header-color;
        display: block;

    }

    span {
        color: @font-color;
    }
}

a.suggestions-advanced-search {
    width: 100%;
    margin-top: 12px;
    color: @header-color;

    &:hover {
        text-decoration: underline;
    }
}

// SIDEBAR
.sidebar {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

// TREE
@media (min-width: 1280px) {
    .cms-rwd,
    .tree-header {
        display: none;
    }

    #tree {
        display: flex;
        flex-direction: column;

        &:before {
            content: 'Kategorie';
            font-size: 18px;
            font-weight: bold;
            text-transform: uppercase;
            font-family: @sec-font-family;
            color: white;
            background-color: @dark-bg;
            padding: 15px;
            border-bottom: 4px solid @main-color;
        }

        > nav {
            background-color: @light-bg;
        }

        li {

            &:last-child {

            }

            &.downarrow {

                > a {

                    > button:after {
                        transform: rotate(90deg);
                    }
                }

                > ul {
                    display: block;
                }
            }
        
            ul {
                display: none;
            }
        }

        li.t0 {
            border-top: 1px solid white;

            &.parent {
                position: relative;
            }

            > ul {
                padding-bottom: 18px;
            }

            > a {
                display: flex;
                align-items: center;
                padding: 14px 15px;
                gap: 12px;
                color: @font-color;
                min-height: 58px;
                position: relative;
                padding-right: 35px;

                > figure {
                    width: 30px;
                    height: 30px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        width: auto;
                        height: auto;
                    }
                }

                &:hover {
                    background-color: #e8e8e8;

                    & ~ button {
                        background-color: #e8e8e8;
                    }
                }
            }
        }

        li:not(.t0) {

            a {
                display: block;
                color: @font-color;
                padding: 4px 15px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        li.t1 > a {
            padding-left: 57px;
        }

        li.t2 > a {
            padding-left: 67px;
        }

        li.t3 > a {
            padding-left: 77px;
        }

        li.selected > a {
            font-weight: bold;
        }

        button {
            margin-left: auto;
            position: absolute;
            top: 0;
            right: 0;
            width: 35px;
            height: 100%;
            display: flex;
            align-items: center;
            padding-left: 7px;
            font-weight: normal;

            &:after {
                content: '\e912';
                font-family: icomoon;
                font-size: 18px;
            }

            &:hover {
                color: @main-color;
            }
        }
    }
}

// SIDEBAR TABS
.gs-tabs {
    overflow: hidden;

    .products-list-tabs {

        > strong {
            font-size: 18px;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            font-family: @sec-font-family;
            color: white;
            background-color: @dark-bg;
            padding: 15px;
            border-bottom: 4px solid @main-color;
        }
    }

    nav {
        display: flex;
        background-color: #585a5d;
        border-bottom: 4px solid @main-color;

        button {
            cursor: pointer;
            user-select: none;
            font-size: 18px;
            font-weight: bold;
            display: block;
            text-transform: uppercase;
            font-family: @sec-font-family;
            color: white;
            padding: 15px;
            flex-grow: 1;

            &[aria-selected="true"] {
                background-color: @dark-bg;
            }
        }
    }

    .products-inner {

    }
}

// SIDEBAR PRODUCTS
.sidebar-product {
    border-bottom: 1px solid @border-color;
    display: flex;
    padding: 19px 15px;
    padding-right: 0;
    position: relative;

    > div {

        &:nth-child(1) {
            width: 70px;
            margin-right: 15px;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
        }

        &:nth-child(2) {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;
        }
    }

    &:hover .sidebar-product-name {
        text-decoration: underline;
    }
}

.sidebar-product-image {
    width: 100%;
    height: 70px; 
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
        align-self: center;
        width: auto;
        height: auto;
    }
}

a.sidebar-product-name {
    font-size: 14px;
    overflow: hidden;
    color: @header-color;
    font-weight: normal;
    display: block;
    line-height: 1.3;
    text-transform: uppercase;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }
}

.sidebar-product-price {
    display: block;
    line-height: 1.3;

    b,
    del {

        &:after {
            content: ' zł';
        }
    }

    b {
        color: @main-color;
    }

    del {
        color: #adadad;
        margin-left: 10px;
    }
}

// PRODUCTS
@keyframes productImageOpacity {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.products-list {
    .grid();
    grid-template-columns: repeat(var(--prodCols), minmax(0, 1fr));
    gap: var(--prodGap);
    width: 100%;
}

.product {
    position: relative;
    .grid();
    grid-template-rows: auto 1fr;
    overflow: hidden;
    text-align: center;

    &.swiper-slide {
        box-sizing: border-box;

        .product-name {
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            display: -webkit-box !important;
            height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.product-image {
    aspect-ratio: 1 / 1;
    position: relative;
    transition: transform .1s ease-in-out;
    margin-bottom: 15px;

    img {

        &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 100%;
            max-height: 100%;
            display: none;
            opacity: 0;
        }
    }
}

a.product-name {
    font-weight: normal;
    display: block;
    color: @header-color;
    transition: opacity .1s ease-in-out;
    z-index: 1; // IMPORTANT
    text-transform: uppercase;
    line-height: 1.57;

    &:after {
        content: '';
        position: absolute;
        inset: 0;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

.product-bottom-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    gap: 9px;
    z-index: 1;
    margin-top: 9px;
}

.product-price {
    width: 100%;
    text-align: center;
    margin-bottom: 6px;

    b {
        color: @main-color;
        font-size: 18px;
        line-height: 27px;

        &:after {
            content: ' zł';
        }
    }
}

a.product-info,
.product-add {
    color: white;
    font-family: @sec-font-family;
    text-transform: uppercase;
    font-weight: bold;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 5px;
    padding-top: 6px;
}

a.product-info {
    background-color: @dark-bg;

    &:hover {
        background-color: darken(@light-bg, 5%);
        color: @font-color;
    }
}

.product-add {
    background-color: @main-color;

    &.disabled {
        display: none;
    }

    &:hover {
        background-color: @header-color;
    }
}

.product-badge {
    position: absolute;
    top: 0;
    right: 0;
    height: 28px;
    line-height: 29px;
    color: white;
    background-color: @error-color;
    padding: 0 10px;
    font-weight: bold;
}

.product-hover {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: rgba(255,255,255,.9);
    transition: opacity .1s ease-in-out;

    > span {
        text-transform: uppercase;
        font-weight: bold;
        line-height: 1.57;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(2 * (var(--fontSize) * 1.57))";
        overflow: hidden;
        text-overflow: ellipsis;
    }

    > p {
        margin-top: 12px;
        color: @font-color;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        display: block;
        display: -webkit-box !important;
        max-height: ~"calc(6 * (var(--fontSize) * var(--lineHeight)))";
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (min-width: 1280px) {
    .product:hover {

        .product-name {
            opacity: 0;
            transition-delay: .2s;
        }

        .product-hover {
            opacity: 1;
            transition-delay: .2s;

            > strong,
            > p,
            > em {
                transition-delay: .2s;
                transform: translateY(0);
            }
        }
    }

    .product.has-photo:hover .product-image img {

        &:first-of-type {
            opacity: 0;
        }

        &:nth-of-type(2) {
            display: block;
            animation: productImageOpacity .2s ease-in-out forwards;
        }
    }
}

// BREADCRUMB
#breadcrumb {
    margin-bottom: 24px;

    ul {
        display: flex;
        flex-wrap: wrap;
    }

    li {
        display: flex;

        &:after {
            content: '\e912';
            margin: 0 6px;
            font-family: icomoon;
        }

        &:last-child {
            font-weight: bold;

            &:after {
                display: none;
            }
        }
    }

    a,
    span {
        color: @font-color;
        display: block;
    }
}

[data-type="mainpage"] #breadcrumb {
    display: none;
}

// LOADER
#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 600px) {
    .category-header-wrapper {
        display: contents;
    }

    .category-description {
        order: 2;
    }
}